<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-board-view">
            <div v-if="!$route.params._success" class="headline text-start mt-8">성공사례 등록</div>
            <div v-else class="headline text-start mt-8">성공사례 상세</div>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1 pb-0">기본정보</v-card-title>
                <v-card-text>
                    <v-row v-if="$route.params._success">
                        <v-col cols="6" class="py-0">
                            <v-text-field :value="(success.writer || {}).name" label="작성자" persistent-placeholder hide-details readonly disabled></v-text-field>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-datetime-field :value="success.createdAt ? success.createdAt.toDateTime() : undefined" label="작성일" persistent-placeholder hide-details @input="(value) => (success.createdAt = value)"></v-datetime-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3">
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="success.subject" label="제목" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-select v-model="success.site" :items="siteItems" label="게시 사이트 선택" persistent-placeholder hide-details multiple @change="clean"></v-select>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3"  v-if="success.site.includes(PANSIM_TYPES.DIVORCE.value)">
                        <v-col  cols="6" class="py-0">
                            <v-select v-model="success.isDivorceMain" :items="mainItems" :label="`${PANSIM_TYPES.DIVORCE.text} 메인 노출 여부`" persistent-placeholder hide-details @change="clean(PANSIM_TYPES.DIVORCE.value)"></v-select>
                        </v-col>
                        <v-col class="py-0" v-if="success.isDivorceMain">
                            <v-text-field v-model="success.divorceRank" label="우선 순위" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row  class="mt-3"  v-if="success.site.includes(PANSIM_TYPES.SEX_OFFENCE.value)">
                        <v-col cols="6" class="py-0">
                            <v-select v-model="success.isSexMain" :items="mainItems" :label="`${PANSIM_TYPES.SEX_OFFENCE.text} 메인 노출 여부`" persistent-placeholder hide-details @change="clean(PANSIM_TYPES.SEX_OFFENCE.value)"></v-select>
                        </v-col>
                        <v-col class="py-0" v-if="success.isSexMain">
                            <v-text-field v-model="success.sexRank" label="우선 순위" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                    </v-row>
                    <!-- <v-row class="mt-3">
                        <v-col cols="12" class="py-0">
                            <v-text-field v-model="success.summary" label="한줄설명" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                    </v-row> -->
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1">썸네일</v-card-title>
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="auto" class="py-0">
                            <v-img v-if="success.thumb" max-width="96" max-height="96" :src="createObjectURL(success.thumb)" contain></v-img>
                            <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                <v-row align="center" class="fill-height" justify="center"><v-icon color="black">mdi-pencil</v-icon></v-row>
                            </v-responsive>
                        </v-col>
                        <v-col class="py-0">
                            <v-file-input v-model="success.thumb" label="썸네일 이미지 462*532(px)" persistent-placeholder class="mt-2 mx-3" show-size accept="image/*"></v-file-input>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-6">
                <v-card-title class="subtitle-1">내용</v-card-title>
                <v-card-text>
                    <naver-smarteditor v-model="success.content" id="content" rows="10"></naver-smarteditor>
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { PANSIM_TYPES } from "@/assets/variables";

import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

import { mdiTagPlus } from "@mdi/js";
import urlParser from "js-video-url-parser";
export default {
    components: {
        VDatetimeField,
        NaverSmarteditor,
    },
    data() {
        return {
            PANSIM_TYPES,
            mdiTagPlus,
            ready: false,

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            success: {
                code: this.$route.query.code,

                subject: undefined,
                content: undefined,
                // summary: undefined,

                site: [],
                isDivorceMain: false,
                isSexMain: false,
                divorceRank: "",
                sexRank: "",
                thumb: undefined,
            },

            siteItems:[
                { text: PANSIM_TYPES.DIVORCE.text, value: PANSIM_TYPES.DIVORCE.value},
                { text: PANSIM_TYPES.SEX_OFFENCE.text, value: PANSIM_TYPES.SEX_OFFENCE.value},
            ],
            mainItems:[
                { text: "노출", value: true},
                { text: "미노출", value: false},
            ],

            videoId: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                if (this.$route.params._success) {
                    var { success } = await api.console.success.get({ _id: this.$route.params._success });

                    if (success.thumb) success.thumb = await api.getResource(success.thumb, true);
                    this.success = success;
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                if(!this.validation()) return;
                let { thumb, ...success } = this.success;
                ////////////////////////////////////////////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                [{ success }] = [success._id ? await api.console.success.put(success) : await api.console.success.post(success)];

                ////////////////////////////////////////////////////////////////
                // 2. 리소스 저장
                ////////////////////////////////////////////////////////////////
                if (thumb) await api.console.success.postThumb(success._id, thumb);
                alert(this.success._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },
        validation(){
            const success = this.success;
            if(!success.subject) throw new Error("제목을 입력해주세요");
            if(!success.site.length) throw new Error("게시할 사이트를 선택해주세요");
            if(success.isDivorceMain && !success.divorceRank) throw new Error("우선순위를 입력해주세요");
            if(success.isDivorceMain && /[^\d]/.test(success.divorceRank)) 
                throw new Error("우선 순위는 숫자만 가능합니다.");
            if(success.isSexMain && !success.sexRank) throw new Error("우선순위를 입력해주세요");
            if(success.isSexMain && /[^\d]/.test(success.sexRank)) 
                throw new Error("우선 순위는 숫자만 가능합니다.");
            if(!success.content) throw new Error("내용을 입력해주세요");
            return true;
        },
        clean(value){
            if(!this.success.site.includes(PANSIM_TYPES.DIVORCE.value)){
                this.success.isDivorceMain = false;
                this.success.divorceRank = ""
            }
            if(!this.success.site.includes(PANSIM_TYPES.SEX_OFFENCE.value)){
                this.success.isSexMain = false;
                this.success.sexRank = ""
            }

            if(value === PANSIM_TYPES.DIVORCE.value) this.success.divorceRank = "";
            if(value === PANSIM_TYPES.SEX_OFFENCE.value) this.success.sexRank = "";
        }
    },
};
</script>
