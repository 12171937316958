var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-board-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [!_vm.$route.params._insight ? _c('div', [_vm.$route.query.code === 'article' ? _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("언론기사 등록")]) : _vm._e(), _vm.$route.query.code === 'youtube' ? _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("영상자료 등록")]) : _vm._e()]) : _c('div', [_vm.$route.query.code === 'article' ? _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("언론기사 asdf")]) : _vm._e(), _vm.$route.query.code === 'youtube' ? _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("영상자료 상세")]) : _vm._e()]), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 pb-0"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_vm.$route.params._insight ? _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm.insight.writer || {}).name,
      "label": "작성자",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": "",
      "disabled": ""
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-datetime-field', {
    attrs: {
      "value": _vm.insight.createdAt ? _vm.insight.createdAt.toDateTime() : undefined,
      "label": "작성일",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    on: {
      "input": function (value) {
        return _vm.insight.createdAt = value;
      }
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "제목",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.insight.subject,
      callback: function ($$v) {
        _vm.$set(_vm.insight, "subject", $$v);
      },
      expression: "insight.subject"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "라벨",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.insight.label,
      callback: function ($$v) {
        _vm.$set(_vm.insight, "label", $$v);
      },
      expression: "insight.label"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-select', {
    attrs: {
      "items": _vm.siteItems,
      "label": "게시 사이트 선택",
      "persistent-placeholder": "",
      "hide-details": "",
      "multiple": ""
    },
    on: {
      "change": _vm.clean
    },
    model: {
      value: _vm.insight.site,
      callback: function ($$v) {
        _vm.$set(_vm.insight, "site", $$v);
      },
      expression: "insight.site"
    }
  })], 1)], 1), ['article', 'youtube'].includes(_vm.insight.code) ? _c('div', [_vm.insight.site.includes(_vm.PANSIM_TYPES.DIVORCE.value) ? _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.mainItems,
      "label": "이혼 사이트 메인 노출 여부",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    on: {
      "change": function ($event) {
        return _vm.clean(_vm.PANSIM_TYPES.DIVORCE.value);
      }
    },
    model: {
      value: _vm.insight.isDivorceMain,
      callback: function ($$v) {
        _vm.$set(_vm.insight, "isDivorceMain", $$v);
      },
      expression: "insight.isDivorceMain"
    }
  })], 1), _vm.insight.isDivorceMain ? _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "우선 순위",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.insight.divorceRank,
      callback: function ($$v) {
        _vm.$set(_vm.insight, "divorceRank", $$v);
      },
      expression: "insight.divorceRank"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.insight.site.includes(_vm.PANSIM_TYPES.SEX_OFFENCE.value) ? _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.mainItems,
      "label": "성범죄 사이트 메인 노출 여부",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    on: {
      "change": function ($event) {
        return _vm.clean(_vm.PANSIM_TYPES.SEX_OFFENCE.value);
      }
    },
    model: {
      value: _vm.insight.isSexMain,
      callback: function ($$v) {
        _vm.$set(_vm.insight, "isSexMain", $$v);
      },
      expression: "insight.isSexMain"
    }
  })], 1), _vm.insight.isSexMain ? _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "우선 순위",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.insight.sexRank,
      callback: function ($$v) {
        _vm.$set(_vm.insight, "sexRank", $$v);
      },
      expression: "insight.sexRank"
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1) : _vm._e()], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("썸네일")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.insight.thumb ? _c('v-img', {
    attrs: {
      "max-width": "96",
      "max-height": "96",
      "src": _vm.createObjectURL(_vm.insight.thumb),
      "contain": ""
    }
  }) : _c('v-responsive', {
    staticClass: "grey lighten-2",
    attrs: {
      "width": "96",
      "height": "96"
    }
  }, [_c('v-row', {
    staticClass: "fill-height",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-file-input', {
    staticClass: "mt-2 mx-3",
    attrs: {
      "label": _vm.$route.query.code === 'article' ? '썸네일 이미지 420*420(px)' : '썸네일 이미지 464*260(px)',
      "persistent-placeholder": "",
      "show-size": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.insight.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.insight, "thumb", $$v);
      },
      expression: "insight.thumb"
    }
  })], 1)], 1)], 1)], 1), ['youtube'].includes(_vm.insight.code) ? _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("유튜브")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "링크주소",
      "placeholder": "https://youtube.com/",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.insight.youtube.url,
      callback: function ($$v) {
        _vm.$set(_vm.insight.youtube, "url", $$v);
      },
      expression: "insight.youtube.url"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary"
    },
    on: {
      "click": _vm.setVideoId
    }
  }, [_vm._v("미리보기")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', {
    attrs: {
      "height": "176"
    }
  }, [_c('youtube', {
    staticStyle: {
      "height": "176px"
    },
    attrs: {
      "video-id": _vm.insight.youtube.id,
      "fitParent": ""
    }
  })], 1)], 1)], 1) : _vm._e(), ['article', 'column', 'review'].includes(_vm.insight.code) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("내용")]), _c('v-card-text', [_c('naver-smarteditor', {
    attrs: {
      "id": "content",
      "rows": "10"
    },
    model: {
      value: _vm.insight.content,
      callback: function ($$v) {
        _vm.$set(_vm.insight, "content", $$v);
      },
      expression: "insight.content"
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }