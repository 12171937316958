var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-board-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [!_vm.$route.query._lawyer ? _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("변호사 등록")]) : _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("변호사 상세")]), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 pb-0"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.items,
      "item-text": "text",
      "item-value": "value",
      "label": "직급",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    on: {
      "change": function () {
        return _vm.lawyer.mainImage = null;
      }
    },
    model: {
      value: _vm.lawyer.isRepresentative,
      callback: function ($$v) {
        _vm.$set(_vm.lawyer, "isRepresentative", $$v);
      },
      expression: "lawyer.isRepresentative"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "이름",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.lawyer.name,
      callback: function ($$v) {
        _vm.$set(_vm.lawyer, "name", $$v);
      },
      expression: "lawyer.name"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "직업",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.lawyer.job,
      callback: function ($$v) {
        _vm.$set(_vm.lawyer, "job", $$v);
      },
      expression: "lawyer.job"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "우선 순위",
      "persistent-placeholder": "",
      "hide-details": "",
      "disabled": _vm.lawyer.isRepresentative
    },
    model: {
      value: _vm.lawyer.rank,
      callback: function ($$v) {
        _vm.$set(_vm.lawyer, "rank", $$v);
      },
      expression: "lawyer.rank"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.lawyerItems,
      "label": "카테고리",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.lawyer.type,
      callback: function ($$v) {
        _vm.$set(_vm.lawyer, "type", $$v);
      },
      expression: "lawyer.type"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 pb-0"
  }, [_vm._v("상세 내용 "), _c('span', {
    staticClass: "caption red--text ml-3"
  }, [_vm._v("* 경력을 빈칸으로 두고 다음 경력을 작성하시면 페이지 내, 한 줄을 넘겨서 다음 경력이 표시됩니다.")]), _c('v-spacer'), _c('v-btn', {
    on: {
      "click": _vm.add
    }
  }, [_vm._v("경력 추가")])], 1), _c('v-card-text', _vm._l(_vm.lawyer.career, function (career, index) {
    return _c('v-row', {
      key: index,
      staticClass: "mt-3",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "py-0"
    }, [_c('v-text-field', {
      attrs: {
        "label": "경력 및 학력",
        "persistent-placeholder": "",
        "hide-details": ""
      },
      model: {
        value: career.txt,
        callback: function ($$v) {
          _vm.$set(career, "txt", $$v);
        },
        expression: "career.txt"
      }
    })], 1), _c('v-col', {
      staticClass: "py-2",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      on: {
        "click": function ($event) {
          return _vm.remove(index);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "size": "20"
      }
    }, [_vm._v("mdi-delete")])], 1)], 1), _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-checkbox', {
      attrs: {
        "label": "메인페이지 노출",
        "persistent-placeholder": "",
        "hide-details": ""
      },
      model: {
        value: career.isMain,
        callback: function ($$v) {
          _vm.$set(career, "isMain", $$v);
        },
        expression: "career.isMain"
      }
    })], 1)], 1);
  }), 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 pb-0"
  }, [_vm._v("이미지 등록")]), _c('v-card-text', [_vm.lawyer.isRepresentative ? _c('v-row', {
    staticClass: "md-4",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.lawyer.mainImage ? _c('v-img', {
    attrs: {
      "max-width": "96",
      "max-height": "96",
      "src": _vm.createObjectURL(_vm.lawyer.mainImage),
      "contain": ""
    }
  }) : _c('v-responsive', {
    staticClass: "grey lighten-2",
    attrs: {
      "width": "96",
      "height": "96"
    }
  }, [_c('v-row', {
    staticClass: "fill-height",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-file-input', {
    staticClass: "mt-2 mx-3",
    attrs: {
      "label": "메인 대표변호사 이미지 708*708(px)",
      "persistent-placeholder": "",
      "show-size": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.lawyer.mainImage,
      callback: function ($$v) {
        _vm.$set(_vm.lawyer, "mainImage", $$v);
      },
      expression: "lawyer.mainImage"
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "pt-2",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.lawyer.image ? _c('v-img', {
    attrs: {
      "max-width": "96",
      "max-height": "96",
      "src": _vm.createObjectURL(_vm.lawyer.image),
      "contain": ""
    }
  }) : _c('v-responsive', {
    staticClass: "grey lighten-2",
    attrs: {
      "width": "96",
      "height": "96"
    }
  }, [_c('v-row', {
    staticClass: "fill-height",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-file-input', {
    staticClass: "mt-2 mx-3",
    attrs: {
      "label": "이미지 588*588(px)",
      "persistent-placeholder": "",
      "show-size": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.lawyer.image,
      callback: function ($$v) {
        _vm.$set(_vm.lawyer, "image", $$v);
      },
      expression: "lawyer.image"
    }
  })], 1)], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }