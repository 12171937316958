<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-board-view">
            <div v-if="!$route.query._lawyer" class="headline text-start mt-8">변호사 등록</div>
            <div v-else class="headline text-start mt-8">변호사 상세</div>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1 pb-0">기본정보</v-card-title>
                <v-card-text>
                    <v-row class="mt-3">
                        <!-- <v-col >
                            <v-text-field v-model="lawyer.rank" label="우선순위" persistent-placeholder hide-details></v-text-field>
                        </v-col> -->
                        <v-col >
                            <v-select v-model="lawyer.isRepresentative" :items="items" item-text="text" item-value="value" label="직급" persistent-placeholder hide-details @change="()=>lawyer.mainImage=null"></v-select>
                        </v-col>
                        <v-col >
                            <v-text-field v-model="lawyer.name" label="이름" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col >
                            <v-text-field v-model="lawyer.job" label="직업" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="lawyer.rank" label="우선 순위" persistent-placeholder hide-details :disabled="lawyer.isRepresentative"></v-text-field>
                        </v-col>
                        <v-col>
                            <v-select v-model="lawyer.type" :items="lawyerItems" label="카테고리" persistent-placeholder hide-details></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card class="mt-4">
                <v-card-title class="subtitle-1 pb-0">상세 내용
                    <span class="caption red--text ml-3">* 경력을 빈칸으로 두고 다음 경력을 작성하시면 페이지 내, 한 줄을 넘겨서 다음 경력이 표시됩니다.</span>         
                    <v-spacer/>
                    <v-btn @click="add">경력 추가</v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row align="center" class="mt-3" v-for="(career, index) in lawyer.career" :key="index">
                        <v-col class="py-0">
                            <v-text-field v-model="career.txt" label="경력 및 학력" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col cols="auto" class="py-2">
                            <v-btn @click="remove(index)"><v-icon size="20">mdi-delete</v-icon></v-btn>
                        </v-col>
                        <v-col cols="auto" class="py-0">
                            <v-checkbox v-model="career.isMain" label="메인페이지 노출" persistent-placeholder hide-details></v-checkbox>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card class="mt-4">
            <v-card-title class="subtitle-1 pb-0">이미지 등록</v-card-title>
                <v-card-text>
                     <v-row v-if="lawyer.isRepresentative" align="center" class="md-4">
                        <v-col cols="auto" class="py-0">
                            <v-img v-if="lawyer.mainImage" max-width="96" max-height="96" :src="createObjectURL(lawyer.mainImage)" contain></v-img>
                            <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                <v-row align="center" class="fill-height" justify="center"><v-icon color="black">mdi-pencil</v-icon></v-row>
                            </v-responsive>
                        </v-col>
                        <v-col class="py-0">
                            <v-file-input v-model="lawyer.mainImage" label="메인 대표변호사 이미지 708*708(px)" persistent-placeholder class="mt-2 mx-3" show-size accept="image/*"></v-file-input>
                        </v-col>
                    </v-row>
                     <v-row align="center" class="pt-2">
                        <v-col cols="auto" class="py-0">
                            <v-img v-if="lawyer.image" max-width="96" max-height="96" :src="createObjectURL(lawyer.image)" contain></v-img>
                            <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                <v-row align="center" class="fill-height" justify="center"><v-icon color="black">mdi-pencil</v-icon></v-row>
                            </v-responsive>
                        </v-col>
                        <v-col class="py-0">
                            <v-file-input v-model="lawyer.image" label="이미지 588*588(px)" persistent-placeholder class="mt-2 mx-3" show-size accept="image/*"></v-file-input>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>

        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { MEMBER_TYPES } from "@/assets/variables";

export default {
    components: {
    },
    data() {
        return {
            ready: false,
            items: [
                { text: "대표", value: true },
                { text: "사원", value: false }
            ],
            ///////////////////////////////////////////////
            // 변호사 정보
            ///////////////////////////////////////////////
            lawyer: {
                name: "",
                // rank: "",
                job: "",
                type: MEMBER_TYPES.LAWYER.value,
                isRepresentative: false,
                career: [
                    { txt: null, isMain: false }
                ],
                rank: null,
                mainImage: null,
                image: null,
            },
            lawyerItems:[
                { text: MEMBER_TYPES.LAWYER.text, value: MEMBER_TYPES.LAWYER.value },
                { text: MEMBER_TYPES.ADVISORY.text, value: MEMBER_TYPES.ADVISORY.value },
            ]
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                if (this.$route.params._lawyer) {
                    let { lawyer } = await api.console.lawyers.get({ _id: this.$route.params._lawyer });
                    if (lawyer.mainImage) lawyer.mainImage = await api.getResource(lawyer.mainImage, true);
                    if (lawyer.image) lawyer.image = await api.getResource(lawyer.image, true);
                    this.lawyer = lawyer;
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                if(!this.validation()) return;
                let { mainImage, image, ...lawyer } = this.lawyer;

                ////////////////////////////////////////////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                [{ lawyer }] = [lawyer._id ? await api.console.lawyers.put({...lawyer, rank: +lawyer.rank}) : await api.console.lawyers.post({...lawyer, rank: +lawyer.rank})];
                // ////////////////////////////////////////////////////////////////
                // // 2. 리소스 저장
                // ////////////////////////////////////////////////////////////////
                if (mainImage) await api.console.lawyers.postThumb({...lawyer, type: "mainImage"}, mainImage);
                if (image) await api.console.lawyers.postThumb({...lawyer, type: "image"}, image);
                // if (files.length > 0) await Promise.all(files.map(async (file, index) => await api.console.boards.files.post({ _lawyer: lawyer?._id, index }, file)));

                alert(this.lawyer._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },

        add(){
            this.lawyer.career.push({ txt: null, isMain: false });
        },
        remove(index){
            this.lawyer.career.splice(index, 1);
        },
        validation(){
            if(!this.lawyer.name) throw new Error("이름을 입력해주세요.");
            if(!this.lawyer.job) throw new Error("직업을 입력해주세요.");
            if(!this.lawyer.rank) throw new Error("우선순위를 입력해주세요.");
            if(/[^\d]/.test(this.lawyer.rank)) throw new Error("우선순위는 숫자만 입력해주세요.");
            if(this.lawyer.isRepresentative && !this.lawyer.mainImage) throw new Error("대표 이미지를 첨부해주세요.");
            if(!this.lawyer.image) throw new Error("이미지를 첨부해주세요.");

            return true;
        }
    },
    watch:{
        "lawyer.isRepresentative"(){
            this.lawyer.isRepresentative ? this.lawyer.rank = "0" : this.lawyer.rank = null;
        }
    }
};
</script>
