<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-board-view">
            <div v-if="!$route.params._insight">
                <div v-if="$route.query.code === 'article'" class="headline text-start mt-8">언론기사 등록</div>
                <div v-if="$route.query.code === 'youtube'" class="headline text-start mt-8">영상자료 등록</div>
            </div>
            <div v-else>
                <div  v-if="$route.query.code === 'article'" class="headline text-start mt-8">언론기사 asdf</div>
                <div v-if="$route.query.code === 'youtube'" class="headline text-start mt-8">영상자료 상세</div>
            </div>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1 pb-0">기본정보</v-card-title>
                <v-card-text>
                    <v-row v-if="$route.params._insight">
                        <v-col cols="6" class="py-0">
                            <v-text-field :value="(insight.writer || {}).name" label="작성자" persistent-placeholder hide-details readonly disabled></v-text-field>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-datetime-field :value="insight.createdAt ? insight.createdAt.toDateTime() : undefined" label="작성일" persistent-placeholder hide-details @input="(value) => (insight.createdAt = value)"></v-datetime-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3">
                        <v-col class="py-0">
                            <v-text-field v-model="insight.subject" label="제목" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col class="py-0">
                            <v-text-field v-model="insight.label" label="라벨" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col class="py-0">
                            <v-select v-model="insight.site" :items="siteItems" label="게시 사이트 선택" persistent-placeholder hide-details multiple @change="clean"></v-select>
                        </v-col>
                    </v-row>
                    <div v-if="['article','youtube'].includes(insight.code)">
                        <v-row class="mt-3"  v-if="insight.site.includes(PANSIM_TYPES.DIVORCE.value)">
                            <v-col  cols="6" class="py-0">
                                <v-select v-model="insight.isDivorceMain" :items="mainItems" label="이혼 사이트 메인 노출 여부" persistent-placeholder hide-details @change="clean(PANSIM_TYPES.DIVORCE.value)"></v-select>
                            </v-col>
                            <v-col class="py-0" v-if="insight.isDivorceMain">
                                <v-text-field v-model="insight.divorceRank" label="우선 순위" persistent-placeholder hide-details></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row  class="mt-3"  v-if="insight.site.includes(PANSIM_TYPES.SEX_OFFENCE.value)">
                            <v-col cols="6" class="py-0">
                                <v-select v-model="insight.isSexMain" :items="mainItems" label="성범죄 사이트 메인 노출 여부" persistent-placeholder hide-details @change="clean(PANSIM_TYPES.SEX_OFFENCE.value)"></v-select>
                            </v-col>
                            <v-col class="py-0" v-if="insight.isSexMain">
                                <v-text-field v-model="insight.sexRank" label="우선 순위" persistent-placeholder hide-details></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- <v-row class="mt-3">
                        <v-col cols="12" class="py-0">
                            <v-text-field v-model="insight.summary" label="한줄설명" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                    </v-row> -->
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1">썸네일</v-card-title>
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="auto" class="py-0">
                            <v-img v-if="insight.thumb" max-width="96" max-height="96" :src="createObjectURL(insight.thumb)" contain></v-img>
                            <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                <v-row align="center" class="fill-height" justify="center"><v-icon color="black">mdi-pencil</v-icon></v-row>
                            </v-responsive>
                        </v-col>
                        <v-col class="py-0">
                            <v-file-input v-model="insight.thumb" :label="$route.query.code === 'article' ? '썸네일 이미지 420*420(px)' : '썸네일 이미지 464*260(px)'" persistent-placeholder class="mt-2 mx-3" show-size accept="image/*"></v-file-input>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-row v-if="['youtube'].includes(insight.code)" class="mt-4">
                <v-col cols="8">
                    <v-card>
                        <v-card-title class="subtitle-1">유튜브</v-card-title>
                        <v-card-text>
                            <v-text-field v-model="insight.youtube.url" label="링크주소" placeholder="https://youtube.com/" persistent-placeholder hide-details />
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer />
                            <v-btn color="secondary" @click="setVideoId">미리보기</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="4">
                    <v-card height="176">
                        <youtube :video-id="insight.youtube.id" fitParent style="height:176px" />
                    </v-card>
                </v-col>
            </v-row>

            <v-card class="mt-6" v-if="['article','column','review'].includes(insight.code)">
                <v-card-title class="subtitle-1">내용</v-card-title>
                <v-card-text>
                    <naver-smarteditor v-model="insight.content" id="content" rows="10"></naver-smarteditor>
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { PANSIM_TYPES } from "@/assets/variables";

import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

import { mdiTagPlus } from "@mdi/js";
import urlParser from "js-video-url-parser";
export default {
    components: {
        VDatetimeField,
        NaverSmarteditor,
    },
    data() {
        return {
            PANSIM_TYPES,
            mdiTagPlus,
            ready: false,

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            insight: {
                code: this.$route.query.code,

                subject: undefined,
                content: undefined,
                label: undefined,
                // summary: undefined,
                youtube: {
                    url: "",
                    id: ""
                },

                site: [],
                isDivorceMain: false,
                isSexMain: false,
                divorceRank: "",
                sexRank: "",
                thumb: undefined,
            },

            siteItems:[
                { text: PANSIM_TYPES.DIVORCE.text, value: PANSIM_TYPES.DIVORCE.value},
                { text: PANSIM_TYPES.SEX_OFFENCE.text, value: PANSIM_TYPES.SEX_OFFENCE.value},
            ],
            mainItems:[
                { text: "노출", value: true},
                { text: "미노출", value: false},
            ],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                if (this.$route.params._insight) {
                    var { insight } = await api.console.insights.get({ _id: this.$route.params._insight });

                    if (insight.thumb) insight.thumb = await api.getResource(insight.thumb, true);
                    this.insight = insight;
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                if(!this.validation()) return;
                let { thumb, ...insight } = this.insight;
                ////////////////////////////////////////////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                [{ insight }] = [insight._id ? await api.console.insights.put(insight) : await api.console.insights.post(insight)];

                ////////////////////////////////////////////////////////////////
                // 2. 리소스 저장
                ////////////////////////////////////////////////////////////////
                if (thumb) await api.console.insights.postThumb(insight._id, thumb);
                alert(this.insight._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },
        validation(){
            const insight = this.insight;
            const code = this.$route.query.code;
            if(!insight.subject) throw new Error("제목을 입력해주세요");
            if(!insight.label) throw new Error("라벨을 입력해주세요");
            if(!insight.site.length) throw new Error("게시할 사이트를 선택해주세요");
            if(insight.isDivorceMain && !insight.divorceRank) throw new Error("우선순위를 입력해주세요");
            if(insight.isDivorceMain && /[^\d]/.test(insight.divorceRank)) 
                throw new Error("우선 순위는 숫자만 가능합니다.");
            if(insight.isSexMain && !insight.sexRank) throw new Error("우선순위를 입력해주세요");
            if(insight.isSexMain && /[^\d]/.test(insight.sexRank)) 
                throw new Error("우선 순위는 숫자만 가능합니다.");

            if(code === "youtube"){
                let id = urlParser.parse(this.insight.youtube.url)?.id || "";
                if(!insight.youtube.url) throw new Error("유튜브 링크를 입력해주세요");
                if(insight.youtube.id !== id) insight.youtube.id = id;
            }

            return true;
        },
        clean(value){
            if(!this.insight.site.includes(PANSIM_TYPES.DIVORCE.value)){
                this.insight.isDivorceMain = false;
                this.insight.divorceRank = ""
            }
            if(!this.insight.site.includes(PANSIM_TYPES.SEX_OFFENCE.value)){
                this.insight.isSexMain = false;
                this.insight.sexRank = ""
            }
            
            if(value === PANSIM_TYPES.DIVORCE.value) this.insight.divorceRank = "";
            if(value === PANSIM_TYPES.SEX_OFFENCE.value) this.insight.sexRank = "";
        },
        setVideoId() {
            this.insight.youtube.id = urlParser.parse(this.insight.youtube.url)?.id || "";
        },
    },
};
</script>
