var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3",
    attrs: {
      "max-width": "800",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("폼메일 상세")]), _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("기본정보")]), _c('v-divider'), _c('v-card-text', [_vm.$route.params._form ? _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "value": (_vm.form || {}).name,
      "label": "작성자",
      "readonly": "",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.form.createdAt ? _vm.form.createdAt.toDateTime() : undefined,
      "label": "작성일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": "",
      "disabled": ""
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "연락처",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: (_vm.form || {}).phone,
      callback: function ($$v) {
        _vm.$set(_vm.form || {}, "phone", $$v);
      },
      expression: "(form || {}).phone"
    }
  })], 1)], 1) : _vm._e()], 1)], 1), _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("내용")]), _c('v-divider'), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "readonly": "",
      "hide-details": "",
      "dense": "",
      "auto-grow": ""
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("확인")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }